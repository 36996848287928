const localConfig = {
    domain: "exchange.nemolatam.co",
    api: "https://exchange.nemolatam.co/api/v1/",
    base: "",
    title: "NemoLATAM",
    captcha: "6LcB-HAqAAAAAPF6RiqCtsIhUnb3SI28gdTJRu6y",
    countryCode: "CO",
    countryCurrency: "COP",
    sentryEnv: "production",
    placesApiKey: "AIzaSyCZ5XUAvjpJooLHwr6bsA1Rm5zPEznKoBU",
  }
  export default localConfig;
  